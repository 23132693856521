// extracted by mini-css-extract-plugin
export var at1024__pr50p = "styles-module--at1024__pr50p--297eb";
export var at640__pr50p = "styles-module--at640__pr50p--3bd68";
export var at768__w50p = "styles-module--at768__w50p--0230c";
export var bg__nobleGray = "styles-module--bg__nobleGray--ff9da";
export var billetShadow = "styles-module--billet-shadow--403e4";
export var c = "styles-module--c--756ba";
export var cc18sf = "styles-module--cc-18sf--99c1f";
export var cc199t = "styles-module--cc-199t--50098";
export var cc1ada = "styles-module--cc-1ada--9039c";
export var cc1aev = "styles-module--cc-1aev--d3450";
export var cc1lut = "styles-module--cc-1lut--58f94";
export var cc1xry = "styles-module--cc-1xry--a8f6b";
export var cc2hzo = "styles-module--cc-2hzo--015bd";
export var cc2n8m = "styles-module--cc-2n8m--e2357";
export var cc4xbu = "styles-module--cc-4xbu--0a0de";
export var ccCaj5 = "styles-module--cc-caj5--76ea4";
export var ccEw5j = "styles-module--cc-ew5j--7a928";
export var ccG73w = "styles-module--cc-g73w--2a245";
export var ccKgeu = "styles-module--cc-kgeu--c7e25";
export var ccKv6t = "styles-module--cc-kv6t--84332";
export var ccPjpe = "styles-module--cc-pjpe--7b8d9";
export var ccUnoo = "styles-module--cc-unoo--3d18d";
export var childAs__seeMore = "styles-module--childAs__seeMore--d6414";
export var colorScheme__background__grayPale = "styles-module--colorScheme__background__gray-pale--2e4ca";
export var colorScheme__buttonGold = "styles-module--colorScheme__buttonGold--304fa";
export var colorScheme__buttonGold__darkBackground = "styles-module--colorScheme__buttonGold__darkBackground--a45f4";
export var colorScheme__linkGold = "styles-module--colorScheme__linkGold--1f749";
export var colorScheme__whiteButton = "styles-module--colorScheme__whiteButton--4aa23";
export var coloredBackground__dark = "styles-module--coloredBackground__dark--53d2f";
export var coloredBackground__light = "styles-module--coloredBackground__light--db93d";
export var common__article__section = "styles-module--common__article__section--35d82";
export var common__article__section__doubleIndentBottom = "styles-module--common__article__section__doubleIndentBottom--d5719";
export var common__article__section__zeroIndentBottom = "styles-module--common__article__section__zeroIndentBottom--4d272";
export var common__article__section__zeroIndentTop = "styles-module--common__article__section__zeroIndentTop--b986f";
export var common__chips = "styles-module--common__chips--c094e";
export var common__headline = "styles-module--common__headline--d541a";
export var common__headline__beveledBottom = "styles-module--common__headline__beveledBottom--d7d94";
export var common__headline__bgLoop = "styles-module--common__headline__bgLoop--f8864";
export var common__leadQuote = "styles-module--common__leadQuote--30e4a";
export var common__projectsTeaser = "styles-module--common__projects-teaser--516d2";
export var common__scrollableTarget = "styles-module--common__scrollableTarget--a68bc";
export var common__teaser = "styles-module--common__teaser--94f27";
export var common__teaser__button = "styles-module--common__teaser__button--780ad";
export var common__textOutline = "styles-module--common__textOutline--269c0";
export var container = "styles-module--container--5236c";
export var crispChatbox = "styles-module--crisp-chatbox--e263b";
export var crispClient = "styles-module--crisp-client--3f67f";
export var darkBackground = "styles-module--darkBackground--de472";
export var darkTxt = "styles-module--darkTxt--c428c";
export var fatButton = "styles-module--fatButton--d4ec5";
export var fs1o5 = "styles-module--fs1o5--a0280";
export var fullWidth = "styles-module--fullWidth--f8a0f";
export var functionalityImages = "styles-module--functionalityImages--f6e25";
export var functionalityItem = "styles-module--functionalityItem--ac6f5";
export var goldBackground = "styles-module--goldBackground--0f003";
export var goldButton = "styles-module--goldButton--7d654";
export var goldButton__casePorter = "styles-module--goldButton__casePorter--52fe1";
export var goldButton__centered = "styles-module--goldButton__centered--bcbb4";
export var goldButton__w14 = "styles-module--goldButton__w14--84bc3";
export var goldFatLink = "styles-module--goldFatLink--c9d4c";
export var icomoon = "styles-module--icomoon--6c58f";
export var item = "styles-module--item--141e0";
export var l = "styles-module--l--194ef";
export var lineBreak = "styles-module--lineBreak--68625";
export var lowercase = "styles-module--lowercase--dea45";
export var maxw20 = "styles-module--maxw20--0a5e6";
export var mb2 = "styles-module--mb2--0d687";
export var mt2 = "styles-module--mt2--beae6";
export var nobr = "styles-module--nobr--56401";
export var primaryButton = "styles-module--primaryButton--f9943";
export var pt2 = "styles-module--pt2--6d0f4";
export var r = "styles-module--r--8084e";
export var reverse = "styles-module--reverse--96964";
export var sectionTitle = "styles-module--section-title--18a07";
export var seeMore = "styles-module--seeMore--53de7";
export var softwareDevelopmentBlock = "styles-module--softwareDevelopmentBlock--dac3d";
export var softwareDevelopmentContent = "styles-module--softwareDevelopmentContent--b46d1";
export var softwareDevelopmentWrapper = "styles-module--softwareDevelopmentWrapper--01fb3";
export var softwareFunctionalityBlock = "styles-module--softwareFunctionalityBlock--8eb52";
export var softwareFunctionalityText = "styles-module--softwareFunctionalityText--5bba4";
export var text = "styles-module--text--8550c";
export var textOutline = "styles-module--textOutline--664a1";
export var transition = "styles-module--transition--c854f";
export var transitionBackground = "styles-module--transition-background--678f9";
export var transitionReverse = "styles-module--transition-reverse--20f88";
export var upTo2cols = "styles-module--upTo2cols--5923b";
export var upTo2colsImgTxt = "styles-module--upTo2colsImgTxt--31368";
export var uppercase = "styles-module--uppercase--665be";
export var v2022 = "styles-module--v2022--9e29f";
export var whiteButton = "styles-module--whiteButton--97fe2";